<template>
    <transition name="slide">
        <div class="card__info"
            v-touch:start="setFirstTouch"
            v-touch:moving ="setTouch"
            v-touch:end="delTouch">
        
            <div class="card_main_title">{{calendarTitle}}</div>
            <div class="card__bottom">
                <div class="card_free_places" 
                v-if="eventSlot.settings.maxAttenders &&
                eventSlot.settings.attenders && !isAttended" 
                :style="`opacity:  ${(100 - (swiped>0?swiped:swiped*-1)) }%`">
                    Free {{eventSlot.settings.maxAttenders - eventSlot.settings.attenders}} spaces
                </div>
                <div class="card_main_info">
                    <div class="slot_attended" v-if="isAttended">
                        Attended successfully!
                    </div>
                    <div class="card_event_title" :style="slotSwipeStyles()">
                        <div class="slot_title">
                            {{eventSlot.title || `Event slot ${eventSlot.index}`}}
                        </div>
                        <div class="date">
                            {{localDateTimeString}}
                        </div>
                    </div>
                    <div class="card_buttons_cont" v-if="!isAttended">
                        <div class="card_buttons_grid">
                            <b-button @click="toCalendarPage">
                                <b-icon icon="close" size="is-small"></b-icon>
                            </b-button>
                            <b-button type="is-info" @click="attendHandler" expanded>Attend</b-button>
                            <div></div>
                        </div>
                    </div>
                    <div class="card_buttons_cont" v-else>
                        <b-button type="is-info" @click="toCalendarPage" expanded>
                            See another slots
                        </b-button>

                        <b-button type="is-info is-light is-text" @click="cancelAttendHandler" expanded>
                            Unattend
                        </b-button>
                    </div>
                </div>
            </div>
            
            <div class="card__image"></div>
        </div>
    </transition>
</template>

<script>
import router from '../../../router.js'
import { cssVw } from '../../../scripts/cssUnits'
import Slot from '../../../scripts/slot'
import YYYYMMDD, { isToday, isTomorrow, isYesterday, pair } from '../../../scripts/date'
import CMS from '../../../service/cms/service'

export default {
    name: 'SlotAttendFrom',
    props: {
        eventSlot: {
            type: Slot,
            required: true,
        },
        slotIsFirst: {
            type: Boolean,
            required: true,
        },
        slotIsLast: {
            type: Boolean,
            required: true,
        },
        onSwipeRight: {
            type: Function,
            required: true,
        },
        onSwipeLeft: {
            type: Function,
            required: true,
        }       
    },
    data() {
        return {
            width: 0,
            touch: 0,
            swiped: 0,
            isSwiped: false,
            firstTouch: 0,
        }
    },
    computed: {
        calendarTitle(){
            return this.$store.getters.calendarTitle
        },
        localDateTimeString(){
            let date = this.eventSlot.period.start.date
            let minute = pair(date.getMinutes())
            let hour = pair(date.getHours())

            if (isToday(date)) return `Today at ${hour}:${minute}`
            if (isTomorrow(date)) return `Tomorrow at ${hour}:${minute}`
            if (isYesterday(date)) return `Yesterday at ${hour}:${minute}`

            return `${date.toLocaleDateString()} at ${hour}:${minute}` 
        },

        isAttended(){
            let userId = this.$store.getters.userId
            let attenders = this.eventSlot.attenders
            return attenders.find(user => user.userId == userId) != undefined
        },
    },
    mounted(){
        this.width = cssVw * 100
    },
    methods:{
        toCalendarPage(){
            let calendarUid = this.$route.params.calendarUid
            let calendarProps = this.$store.getters.calendarProperties
            router.replace({
                name:'calendar', 
                params:{calendarUid: calendarUid},
                query: {
                    dt: YYYYMMDD(this.eventSlot.period.start.date),
                    w: calendarProps.columnCount,
                }
            })
        },

        setFirstTouch(event){
            const touch = event.targetTouches[0]
            this.firstTouch = touch.clientX
        },
        setTouch(event){
            const touch = event.targetTouches[0]
            this.touch = touch.clientX

            let swiped = ((this.touch - this.firstTouch) / this.width)*100

            let leftMovement = this.swiped  > 0
            let rightMovement = this.swiped < 0

            if (Math.abs(this.swiped) > 10 && ((leftMovement && this.slotIsFirst) || (rightMovement && this.slotIsLast))){
                return
            }

            this.isSwiped = Math.abs(this.swiped) > 50
            if (!this.isSwiped){
                this.swiped = swiped  
            } 
        },

        delTouch(){
            console.log('this.swiped :>> ', this.swiped);
            if (this.swiped < -20){
                this.onSwipeRight()
            }
            if (this.swiped > 20) {
                this.onSwipeLeft()                                
            }

            this.firstTouch = 0
            this.touch = 0
            this.swiped = 0
            this.isSwiped = false
        },
 
        attendHandler(){
            let calendarUid = this.$route.params.calendarUid
            let slotId = this.eventSlot.index
            CMS.slots.attend(calendarUid, slotId)
                .then(data => {
                    if (data.err)
                        throw data.err
                    // console.log('data :>> ', data);
                    let userId = this.$store.getters.userId
                    this.eventSlot.addAttenders([{userId}])
                })
        }, 

        cancelAttendHandler(){
            let slotId = this.eventSlot.index
            CMS.slots.unattend(slotId)
                .then(data => {
                    if (data.err)
                        throw data.err
                    // console.log('data :>> ', data);
                    let userId = this.$store.getters.userId
                    this.eventSlot.removeAttender(userId)
                })
        },

        slotSwipeStyles(){
            let swipeInProcess = Math.abs(this.swiped) > 20
            if (!swipeInProcess)
                return "left:20px"

            let opacity = 100 - Math.abs(this.swiped)

            return Math.abs(this.swiped) > 20? 
                `left: calc(20px + ${this.swiped}%); opacity: ${opacity}%`
                :`left:20px`
        }
    }
}
</script>

<style scoped>
.card_main_info{
    background: white;
    transition: height .3s;
}

.card__bottom{
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 2;
}

.card_main_title{
    height: 40px;
    width: 100%;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 18px;
    z-index: 2;
    color: white;
}

.warning{
    color: red
}

.card_event_title{
    color: black;
    font-weight: 400;
    font-size: 18px;
    position: relative;
    padding: 10px 0;
    padding-bottom: 0;
    width: calc(100% - 40px);
}

.date {
    font-size: 14px;
}

.slot_title{
    font-size: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    height: 24px;
    width: 100%;
}


.swiped{
    position: absolute;
}

.card_free_places{
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

}

.card__info {
    height: 100vh;  
    -moz-background-size: 100%; /* Firefox 3.6+ */
    -webkit-background-size: 100%; /* Safari 3.1+ и Chrome 4.0+ */
    -o-background-size: 100%; /* Opera 9.6+ */
    background-size: cover;
    position: relative;
}

.card__image{
    height: 100vh;
    background: radial-gradient(transparent 0%,  rgba(0,0,0,0.55) 100%);
}

.card_buttons_cont{
    padding: 20px;
    padding-top: 10px;
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 10px;
}

.card_buttons_grid{
    display: grid;
    grid-template-columns: 40px calc(100% - 50px - 50px) 40px;
    column-gap: 10px;
}

.card__button{
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    border-radius: 3px;
    font-weight: 600;
}   

.close{
    background: lightgray;
}

.attend{
    background: green;
    color: white;
}

.unselectable {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently */
}

/*
    Animations 
*/ 

.slide-enter-active,
.slide-leave-active {
  transition: all 0.75s ease-out;
}
.slide-enter-to {
  position: absolute;
  right: 0;
}
.slide-enter-from {
  position: absolute;
  right: -100%;
}
.slide-leave-to {
  position: absolute;
  left: -100%;
}
.slide-leave-from {
  position: absolute;
  left: 0;
}

.slot_attended{
    width: fit-content;
    position: relative;
    padding-top: 10px;
    left: 20px;
}

</style>